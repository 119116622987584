import React from 'react';
import { Box } from '@mui/material';
import LoanIncompleteCard from '@views/Dashboard/Home/LoanIncompleteCard';
import MainButton from '@components/buttons/Buttons';
import ApplicationStatusCard from '@views/Dashboard/Home/ApplicationStatusCard';
import training_thumb from '@images/bg/jas_hero_5.jpg';
import { Col, Container } from 'react-bootstrap';
import TrainingProgramCard from '@views/Dashboard/Home/TrainingProgramCard';
import SeeMoreTitle from '@views/Dashboard/Home/SeeMoreTitle';
import TrainingPreviewCard from '@views/Dashboard/Home/TrainingPreviewCard';
import BlogPostCard from '@views/Dashboard/Home/BlogPostCard';
import { getUserProfile } from 'src/api/User/User';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { defaultBlue } from '@/utils/constants/colors.constants';
import constant from '@/utils/constants/constants';
// import training_thumb_1 from "@images/bg/jas_hero_6.jpg";

const MobileDashboard = () => {
  const user = JSON.parse(window.localStorage.getItem('user'));
  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery('user-data', getUserProfile);
  const data = responseData?.data;
  const is_application_complete = data?.profile?.is_application_complete;
  const navigate = useNavigate();

  const titleStyle = {
    fontSize: '1.5rem',
    padding: '1.5rem',
    borderBottom: '1px solid #e1e1e1',
    marginBottom: '2rem',
    marginTop: '1rem',
  };

  const titleText = {
    fontSize: '1.1rem',
    fontWeight: '600',
    marginBottom: '1rem',
  };

  const sectionWrapper = {
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
  };

  const showGrantFormButton = () => {
    let grantStage = false;

    if (data) {
      if (data?.profile?.is_business_plan_application_complete) {
        return false;
      }
      data?.approvals?.forEach((approval) => {
        if (approval?.approval_type == 'advanced_training_approval') {
          grantStage = true;
        }
      });
    }
    return (grantStage && constant.SHOW_BUSINESS_FORM) == true;
  };

  // const showBusinessPlanFormButton = () => {
  //   let showBusinessPlan = false;
  //   if (data?.profile?.grant_information?.succession_plan) {
  //     showBusinessPlan = true;
  //   }
  //   return showBusinessPlan;
  // };

  const statusComponent = (_status) => {
    let status = '';
    let style = {
      fontSize: '0.7rem',
      backgroundColor: '#ED1C2420',
      color: '#ED1C24',
      padding: '0.3rem',
      borderRadius: '0.3rem',
      fontWeight: 'bold',
      width: 'fit-content',
    };

    switch (_status) {
      case 'pending_application_approval':
        status = 'Pending Approval';
        style.backgroundColor = '#f9ffb8';
        style.color = '#838c01';
        break;
      case 'approved_application_approval':
        status = 'Approved for Interview';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_for_basic_training':
        status = 'Approved for Basic Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_for_intermediate_training':
        status = 'Approved for Intermediate Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_for_advanced_training':
        status = 'Approved for Advanced Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant':
        status = 'Approved for Grant Application';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant_pre_disbursement':
        status = 'Approved for Pre Disbursement';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant_post_disbursement':
        status = 'Approved for Post Disbursement';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'disqualified':
        status = 'Disqualified';
        style.backgroundColor = '#f9ffb8';
        style.color = '#838c01';
        break;
      default:
        status = 'Pending';
    }

    return (
      <div className="application-status" style={style}>
        {status}
      </div>
    );
  };
  if (isLoading)
    return (
      <Container className="p-4">
        <h2 className="mt-4 me-4">Loading...</h2>;
      </Container>
    );

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F9FAFD',
        height: '100%',
      }}
    >
      <Box sx={titleStyle}>
        Welcome, {user?.first_name || user?.user?.first_name}
      </Box>
      <Box sx={sectionWrapper}>
        <Box sx={titleText}>Application Status</Box>
        <Box style={{ marginBottom: '10px' }}>
          {statusComponent(data?.application_process_status)}
        </Box>
        <Box>
          <ApplicationStatusCard isMobile={true} data={data} />
        </Box>
        <Box mt={'1rem'}>
          {!is_application_complete ? (
            <LoanIncompleteCard>
              <MainButton
                text="Continue Application"
                dark={true}
                link={'/onboarding/personal_information'}
              />
            </LoanIncompleteCard>
          ) : null}
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box style={{ marginRight: '10px' }}>
            {showGrantFormButton() == true && (
              <Button
                onClick={() => {
                  navigate('/grants/businessplan/guidance');
                }}
                style={{
                  marginTop: '1rem',
                  textTransform: 'initial',
                  fontFamily: 'inherit',
                  backgroundColor: defaultBlue,
                  color: 'white',
                }}
              >
                Start Business Plan Form
              </Button>
            )}
          </Box>

          {/* {showBusinessPlanFormButton() == true && (
            <Button
              style={{
                marginTop: '1rem',
                textTransform: 'initial',
                fontFamily: 'inherit',
                backgroundColor: 'white!important',
                color: defaultBlue,
                border: `1px solid ${defaultBlue}`,
              }}
              onClick={() => {
                navigate('/grants/businessplan/application_summary');
              }}
            >
              Start Business Plan Form
            </Button>
          )} */}
        </Box>
      </Box>

      <Box sx={sectionWrapper}>
        <Box sx={titleText}>Training Programs</Box>
        <Box mt={'1rem'}>
          <Col xs={12} md={12}>
            <TrainingProgramCard
              title="Financial Accounting 101"
              training_thumbnail="https://images.unsplash.com/photo-1508243529287-e21914733111?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            />
          </Col>
          <Col xs={12} md={12}>
            <TrainingProgramCard
              title="Business Accounting for beginners"
              training_thumbnail={training_thumb}
            />
          </Col>
        </Box>
      </Box>

      <Box sx={sectionWrapper}>
        <Box sx={titleText}>Blog Posts</Box>
        <Box mt={'1rem'}>
          <Col xs={12} md={12} xl={12}>
            <SeeMoreTitle title="Blog Posts" />
            <BlogPostCard />
          </Col>
        </Box>
      </Box>

      <Box sx={sectionWrapper}>
        <Box sx={titleText}>Announcements</Box>
        <Box mt={'1rem'}>
          <Col xs={12} md={12} xl={12}>
            <SeeMoreTitle title="Announcements" />
            <TrainingPreviewCard />
          </Col>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileDashboard;
